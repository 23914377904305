import { useState, useEffect } from 'react';

const useOnScreen = (ref, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observerCallback = ([entry]) => {
      setIntersecting(entry.isIntersecting);
    };

    // Create options object
    const options = { rootMargin };

    // Create the observer
    const observer = new IntersectionObserver(observerCallback, options);

    // Get current element
    const currentElement = ref.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [ref, rootMargin]); // Depend on ref and rootMargin

  return isIntersecting;
};

export default useOnScreen;
