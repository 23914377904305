import React from 'react';

const Footer = () => {
  const linkStyle = {
    color: 'white', 
    textDecoration: 'none',
    margin: '0 10px',
    fontSize: '0.9em',
    transition: 'color 0.3s'
  };

  return (
    <footer className='footer'>
      <h5 style={{ margin: '0 0 20px 0' }}>Reach out</h5>
      <div style={{ marginBottom: '20px' }}>
        
        <a href="https://discord.gg/wxxS4DyQrY" style={linkStyle}> <strong>Join our Discord Community</strong></a>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <a href="https://www.github.com/" target="_blank" rel="noopener noreferrer" style={linkStyle}><strong>Githhub</strong></a> | 
        <a href="https://www.linkedin.com/company/fusor-ai" target="_blank" rel="noopener noreferrer" style={linkStyle}><strong>Linkedin</strong></a>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <a href="#" style={linkStyle}>Helpdesk</a> | 
        <a href="#" style={linkStyle}>Terms and Condition</a>
      </div>
      <div style={{ fontSize: '0.8em' }}>
        © {new Date().getFullYear()} <strong>FusorAi&trade;</strong>. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
