import React from 'react';
import fusorImage from './fusor.png'; // Ensure the image is in your project directory

const Introduction = () => {
  const introductionText = "Welcome to the cutting edge of AI infrastructure. At Fusor, we're redefining the AI hardware landscape by integrating custom, Ubuntu-based operating systems with state-of-the-art hardware solutions. Our system is engineered to effortlessly manage and upgrade hardware components, ensure redundancy, control power and noise, and provide robust backup and alerting mechanisms. We specialize in optimizing hardware specifically for current AI workloads, like GPT2+ and Mistral, offering comprehensive guides and configurations to maximize performance. Our service extends beyond hardware, including training, consulting, and unparalleled 24/7 technical and premium software support. As AI becomes integral to business operations, our mission is to provide an affordable, reliable, and high-performance platform, tailored to your AI project's needs and ready for deployment in just a few weeks. Trust us to be your partner in navigating the AI revolution.";

  return (
    <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <h1 className='content' style={{ fontSize: '34px' }}>About Fusor <span style={{ fontSize: '0.5em', verticalAlign: 'super', fontWeight: 'lighter', color: '#dbfa60' }}> Ai</span></h1>
      <p className='content'>{introductionText}</p>
    
    </section>
  );
};

export default Introduction;
