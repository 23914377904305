import React from 'react';
import aiLogo from './ai-logo-bg.png'; // Adjust the path as necessary

const Logo = () => {
    return (
      <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
        <img src={aiLogo} alt="AI Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
        <div style={{ marginLeft: '10px', textAlign: 'left' }}>
          <span style={{ display: 'block', fontWeight: 'bold', color: 'white', fontSize: '24px' }}>
            Fusor<span style={{ fontSize: '0.5em', verticalAlign: 'super', fontWeight: 'lighter' }}> Ai</span>

          </span>
          <span style={{ display: 'block', color: 'white', fontSize: '16px' }}>AI Solutions for Your Peace of Mind</span>
        </div>
      </div>
    );
};

export default Logo;
