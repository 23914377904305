import React, { useRef } from 'react';
import './App.css';
import Header from './components/Header';
import Introduction from './components/Introduction';
import useOnScreen from './hooks/useOnScreen';
import Footer from './components/Footer';



// Lazy load other components
const Features = React.lazy(() => import('./components/Features'));
const Benefits = React.lazy(() => import('./components/Benefits'));
const Offerings = React.lazy(() => import('./components/Offerings'));
const Glow = React.lazy(() => import('./components/Glow'));
const Closing = React.lazy(() => import('./components/Closing'));

function App() {
  const offeringsRef = useRef();
  const isOfferingsVisible = useOnScreen(offeringsRef);

  return (
    <div className="App">
      <Header />
      <Introduction />
      <div ref={offeringsRef}>
        {isOfferingsVisible && (
          <React.Suspense fallback={<div>Loading...</div>}>
            <center>
            <Glow />
            </center>
            <Offerings />
            
            <Features />
            <Benefits />
            <Closing />
          </React.Suspense>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default App;
