import React from 'react';
import Logo from './Logo';

const Header = () => {
  return (
    <header className="navbar navbar-expand-lg navbar-dark custom-navbar">
      <div className="container-fluid">
        <Logo />
        <nav className="navbar-nav me-auto mb-2 mb-lg-0">
          {/* Navigation links here */}
        </nav>
  {/*       <div className="ml-auto">
          <button className="btn pre-order-btn mx-2" type="button">Blog</button>
        </div>
        <div className="ml-auto">
            <a href="mailto:info@Inferner.ai" className="btn pre-order-btn">Contact</a>
    </div> */}

    
      </div>
    </header>
  );
};

export default Header;
